:root {
    --cui-primary: #ff7e67;
    --cui-primary-rgb: 255,126,103;
    --cui-primary-border-subtle: #ff9e8d;
    --cui-primary-bg-subtle: #f5f5f5;
    --cui-primary-text-emphasis: black;
    --cui-link-color: black;
    --cui-link-color-rgb: 0,0,0;
    --cui-modal-width: 768px;
    --cui-progress-bar-bg: #ff7e67;
}

.primary-color {
    color: #ff7e67;
}

.primary-bg-color {
    background-color: #ff7e67;
    color: white;
}

.btn-primary {
    --cui-btn-bg: #ff7e67;
    --cui-btn-border-color: #ff7e67;
    --cui-btn-hover-bg: #ff674d;
    --cui-btn-hover-border-color: #ff674d;
    --cui-btn-active-bg: #ff5233;
    --cui-btn-active-border-color: #ff5233;
}

.trim-1 {
    -webkit-line-clamp: 1;
}

.trim-2 {
    -webkit-line-clamp: 2;
}

.trim-3 {
    -webkit-line-clamp: 3;
}

.hover-pointer:hover {
    cursor: pointer;
}

.login-page-logo-container {
    width: 400px;
}

.edit-venue-form-label {
    font-weight: 500;
}

.event-modal-information-title {
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-modal-information-content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.business-modal-body {
    min-width: 400px;
}

.modal-subtitle {
    color: gray;
    font-size: 14px;
}

.black {
    color: black;
}

.white {
    color: white;
}

.darkgray {
    color: darkgray
}

.dodgers-blue {
    color: #005A9C
}

.form-check-input.primary-color {
    --cui-form-check-input-checked-bg-color: var(--cui-primary);
}

.calendar-event-color-gray {
    --confirmed-color: #888888;
    color: var(--confirmed-color);
}

.form-check-input.calendar-event-color-gray {
    --cui-form-check-input-checked-bg-color: var(--confirmed-color);
    --cui-form-check-input-checked-border-color: var(--confirmed-color);
}

.badge.calendar-event-color-gray {
    background-color: var(--confirmed-color);
    color: white;
}

.calendar-event-color-1 {
    --confirmed-color: #d50201;
    color: var(--confirmed-color);
}

.form-check-input.calendar-event-color-1 {
    --cui-form-check-input-checked-bg-color: var(--confirmed-color);
    --cui-form-check-input-checked-border-color: var(--confirmed-color);
}

.badge.calendar-event-color-1 {
    background-color: var(--confirmed-color);
    color: white;
}

.calendar-event-color-2 {
    --confirmed-color: #8f24aa;
    color: var(--confirmed-color);
}

.form-check-input.calendar-event-color-2 {
    --cui-form-check-input-checked-bg-color: var(--confirmed-color);
    --cui-form-check-input-checked-border-color: var(--confirmed-color);
}

.badge.calendar-event-color-2 {
    background-color: var(--confirmed-color);
    color: white;
}

.calendar-event-color-3 {
    --confirmed-color: #098143;
    color: var(--confirmed-color);
}

.form-check-input.calendar-event-color-3 {
    --cui-form-check-input-checked-bg-color: var(--confirmed-color);
    --cui-form-check-input-checked-border-color: var(--confirmed-color);
}

.badge.calendar-event-color-3 {
    background-color: var(--confirmed-color);
    color: white;
}

.calendar-event-color-4 {
    --confirmed-color: #3f51b5;
    color: var(--confirmed-color);
}

.form-check-input.calendar-event-color-4 {
    --cui-form-check-input-checked-bg-color: var(--confirmed-color);
    --cui-form-check-input-checked-border-color: var(--confirmed-color);
}

.badge.calendar-event-color-4 {
    background-color: var(--confirmed-color);
    color: white;
}

.calendar-event-color-5 {
    --confirmed-color: #f5521d;
    color: var(--confirmed-color);
}

.form-check-input.calendar-event-color-5 {
    --cui-form-check-input-checked-bg-color: var(--confirmed-color);
    --cui-form-check-input-checked-border-color: var(--confirmed-color);
}

.badge.calendar-event-color-5 {
    background-color: var(--confirmed-color);
    color: white;
}

.calendar-event-color-6 {
    --confirmed-color: #f5a70a;
    color: var(--confirmed-color);
}

.form-check-input.calendar-event-color-6 {
    --cui-form-check-input-checked-bg-color: var(--confirmed-color);
    --cui-form-check-input-checked-border-color: var(--confirmed-color);
}

.badge.calendar-event-color-6 {
    background-color: var(--confirmed-color);
    color: white;
}

.calendar-event-color-7 {
    --confirmed-color: #049be5;
    color: var(--confirmed-color);
}

.form-check-input.calendar-event-color-7 {
    --cui-form-check-input-checked-bg-color: var(--confirmed-color);
    --cui-form-check-input-checked-border-color: var(--confirmed-color);
}

.badge.calendar-event-color-7 {
    background-color: var(--confirmed-color);
    color: white;
}

.event-type-color-circle {
    height: 20px;
    width: 20px;
    border-radius: 50%;
}

.confirmed {
    border-color: var(--confirmed-color);
    --fc-event-bg-color: var(--confirmed-color);
    --fc-event-border-color: var(--confirmed-color);
    --dot-border: calc(var(--fc-daygrid-event-dot-width)/2.5) solid;
    --dot-outline: calc(var(--fc-daygrid-event-dot-width)/4) solid;
}

.pending {
    border-color: white;
    --fc-event-bg-color: rgba(200, 200, 200, 0.25);
    --fc-event-text-color: rgb(150, 150, 150);
    --dot-border: calc(var(--fc-daygrid-event-dot-width)/2.5) double;
    --dot-outline: calc(var(--fc-daygrid-event-dot-width)/4) solid;
}

.fc-daygrid-event-dot {
    border-color: inherit !important;
    border: var(--dot-border);
    outline: var(--dot-outline);
}

.fc-resource {
    height: 66px;
    vertical-align: middle !important;
}

.fc-col-header-cell-cushion {
    width: 100%;
}

.fc-media-screen {
    height: 100%;
}

.fc-view-harness {
    height: 100% !important;
}

.fc-daygrid-body {
    height: 100%;
}

.fc-resourceTimeGridDay-view .fc-scrollgrid-sync-table {
    height: 66.5px !important;
}

.fc-dayGridMonth-view .fc-scrollgrid-sync-table {
    height: 100% !important;
}

.fc .fc-popover {
    z-index: 1000;
}

.fc-event{
    cursor: pointer;
}

.day-of-the-week-circle-container {
    display: inline-block;
    margin: 5px;
    cursor: pointer;
}

.day-of-the-week-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid var(--cui-primary);
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.day-of-the-week-circle.inactive {
    background-color: transparent;
    color: black;
}

.day-of-the-week-circle.active {
    background-color: var(--cui-primary);
    color: white;
}

.display-line-break {
    white-space: pre-line;
}

.datepicker-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    box-sizing: border-box;
}

.datepicker-input::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.sortable {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
}

/* sidebar */
.sidebar-container {
    position: fixed;
}

.main-content-container {
    background-color: #eeeeee;
    min-height: 100vh;
}

.sidebar-expanded {
    margin-left: 16rem;
}

.sidebar-narrowed {
    margin-left: 4rem;
}

/* delete image button */
.sortable-close {
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--cui-primary);
    opacity: 0.75;
}
.sortable-close:hover {
    opacity: 1;
}

.filter-block {
    background-color: #f3f4f7;
    border-radius: var(--cui-border-radius);
    padding: 6px 12px;
    border-width: 1px;
}

.cs-message__content {
    white-space: normal;
}

.cs-message__custom-content p {
    margin-bottom: 0;
}

.cs-message__custom-content ol {
    margin-top: 1rem;
}

.cs-message__custom-content ul {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.cs-message__custom-content a {
    text-decoration: underline !important;
}

.shadow-3 {
    box-shadow: 0 0 3px lightgray;
}

.external-link:hover {
    color: black !important;
    text-decoration: underline !important;
    cursor: pointer;
}

.external-link:hover .hover-grey {
    background-color: #f5f5f5;
}

.hover-grey:hover {
    background-color: #f5f5f5;
}

/* subscription plan */
.font-19 {
    font-size: 19px;
}

.box-shadow-5 {
    box-shadow: lightgray 0 0 5px;
    border-width: 0;
}

/* profile avatar */
.cs-avatar img {
    object-fit: cover;
}

.avatar-md img {
    height: 2.5rem;
    width: 2.5rem;
    object-fit: cover;
}

.avatar-xl img{
    height: 60px;
    width: 60px;
    object-fit: cover;
}

.progress-text {
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.accordion {
    --cui-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--cui-primary-rgb),.25);
}
